import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
  Typography,
  Paper,
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import homePcBg from './assets/img/home-pc-bg.png'
import homeMBg from './assets/img/home-m-bg.png'
import ear from './assets/img/ear.png'
const HomeStyle = styled.div`
  @media screen and (max-width: 1000px) {
    .home {
      background: url(${homeMBg}) !important;
      background-size: 100% 100% !important;
      padding-top: 78px !important;
    }
    .content {
      flex-direction: column;
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .home {
    padding-top: 134px;
    width: 100%;
    height: 100vh;
    background: url(${homePcBg});
    background-size: 100% 100%;
    position: relative;
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      img {
        width: 40vw;
      }
      .text {
        text-wrap: nowrap;
        span {
          display: block;
          color: #ffe600;
          font-size: 6vw;
          font-weight: 800;
          line-height: 1;
          &:last-child {
            color: #fff;
            font-size: 4vw;
            margin-top: 20px;
          }
        }
      }
      .button {
        margin-top: 20px;
        background-color: #ffe600;
        height: 50px;
        border-radius: 50px;
        color: #2e3dc8;
        font-size: 14px;
        font-weight: 600;
        float: right;
        text-wrap: nowrap;
      }
    }
  }
`
export default function Home() {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)
  const [evmAddress, setEvmAddress] = useState('')
  const [postUrl, setPostUrl] = useState('')
  const [copied, setCopied] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setStep(1)
    setEvmAddress('')
    setPostUrl('')
    setCopied(false)
  }

  const handleNext = () => {
    if (step < 4) {
      setStep(step + 1)
    }
  }

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1)
    }
  }

  const handleEvmAddressChange = (e) => {
    setEvmAddress(e.target.value)
  }

  const handlePostUrlChange = (e) => {
    setPostUrl(e.target.value)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `I just found a piece of Trump's ear! Check it out at trumpsmissingear.xyz and claim your share.`
    )
    setCopied(true)
  }

  return (
    <HomeStyle>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '600px',
            maxWidth: '600px', // Increase the width of the dialog
          },
        }}
      >
        {step === 1 && (
          <>
            <DialogTitle>Step 1: Enter Your EVM Address</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter your EVM address to continue.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="evmAddress"
                label="EVM Address"
                type="text"
                fullWidth
                variant="standard"
                value={evmAddress}
                onChange={handleEvmAddressChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{
                  '&:hover': {
                    backgroundColor: 'darkblue',
                    color: 'white',
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleNext}
                sx={{
                  '&:hover': {
                    backgroundColor: 'darkblue',
                    color: 'white',
                  },
                }}
              >
                Next
              </Button>
            </DialogActions>
          </>
        )}
        {step === 2 && (
          <>
            <DialogTitle>Step 2: Share on X</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please share the following message on X:
              </DialogContentText>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  marginTop: 2,
                  backgroundColor: 'grey.200',
                }}
              >
                <Typography>
                  I just found a piece of Trump's ear! Check it out at trumpsmissingear.xyz and claim your share.
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body2" color="textSecondary">
                    Copy this message:
                  </Typography>
                  <IconButton onClick={handleCopy}>
                    <ContentCopyIcon />
                  </IconButton>
                </Box>
              </Paper>
              {copied && (
                <DialogContentText color="primary">
                  Copied to clipboard!
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleBack}
                sx={{
                  '&:hover': {
                    backgroundColor: 'darkblue',
                    color: 'white',
                  },
                }}
              >
                Back
              </Button>
              <Button
                onClick={handleNext}
                sx={{
                  '&:hover': {
                    backgroundColor: 'darkblue',
                    color: 'white',
                  },
                }}
              >
                I have posted
              </Button>
            </DialogActions>
          </>
        )}
        {step === 3 && (
          <>
            <DialogTitle>Step 3: Provide Your Post Link</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please provide the URL of your post on X.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="postUrl"
                label="Post URL"
                type="text"
                fullWidth
                variant="standard"
                value={postUrl}
                onChange={handlePostUrlChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleBack}
                sx={{
                  '&:hover': {
                    backgroundColor: 'darkblue',
                    color: 'white',
                  },
                }}
              >
                Back
              </Button>
              <Button
                onClick={handleNext}
                sx={{
                  '&:hover': {
                    backgroundColor: 'darkblue',
                    color: 'white',
                  },
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </>
        )}
        {step === 4 && (
          <>
            <DialogTitle>Congratulations!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Congratulations, you just found Trump's missing ear! We will airdrop it to your wallet.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{
                  '&:hover': {
                    backgroundColor: 'darkblue',
                    color: 'white',
                  },
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <div className="home" id='Home'>
        <div className="content">
          <img src={ear} alt="banner" />
          <div className="right">
            <div className="text">
              <span>TRUMP'S </span>
              <span>MISSING EAR </span>
            </div>
            <Button
              variant="contained"
              className="button"
              onClick={() => handleClickOpen()}
            >
              get a piece of trump's missing ear
            </Button>
          </div>
        </div>
      </div>
    </HomeStyle>
  )
}
