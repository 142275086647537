import React from 'react'
import styled from 'styled-components'
import communityPcBg from './assets/img/community-pc-bg.png'
import communityMBg from './assets/img/community-m-bg.png'
import telegram from './assets/img/telegram.svg'
import twitter from './assets/img/twitter.png'
import discord from './assets/img/discord.png'
const CommunityStyle = styled.div`
  @media screen and (max-width: 1000px) {
    .community {
      background: url(${communityMBg}) no-repeat !important;
      background-size: 100% 100% !important;
      padding-top: 0 !important;
      .des {
        padding: 0 30px !important;
        font-size: 12px !important;
      }
      .foot .concat span {
        font-size: 12px !important;
      }
    }
  }
  .community {
    width: 100%;
    min-height: 100vh;
    background: url(${communityPcBg});
    background-size: 100% auto;
    background-position: center 35%;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    .title {
      font-size: 4vw;
      color: #ffe600;
      text-align: center;
    }
    .des {
      color: #fff;
      padding: 0 40vw;
      margin-top: 4vw;
      font-size: 18px;
      word-break: break-all;
      .highlight-yellow {
        color: #ffe600;
        font-weight: bold;
      }
    }

    .foot {
      .concat {
        display: flex;
        flex-wrap: wrap;
        color: #fff;
        justify-content: center;
        margin-top: 4vw;
        a {
          & + a {
            margin-left: 20px;
          }
          img {
            height: 4vw;
          }
        }
      }
    }
  }
`
export default function Communtiy() {
  return (
    <CommunityStyle>
      <div className="community" id="Community">
        <div className="community-info">
          <h5 className="title">Community</h5>
          <p className="des">
            UNITED, WE HONOR AND BOLSTER MR.TRUMP, THE ONE AND ONLY <span className="highlight-yellow">TME</span> TOKEN
            EMBODIESTHE PEOPLE'S FORTUNE AND TRIUMPH. LET'S JOIN THE CAUSE AND 
            <span className="highlight-yellow"> MAKE TRUMP'S MISSING EAR GREAT AGAIN! </span>
          </p>
        </div>
        <div className="foot">
          <h5 className="title" id="JoinUS">
            Join US 
          </h5>
          <div className="concat">
            <a href="https://www.dextools.io/app/cn/base/pair-explorer/0x81062202e90ed9294709be753cdc97d9cf9367b8?t=1721325952024">
              <img src={discord} alt="dextools" />
            </a>
            <a href="https://x.com/BaseCoinTME">
              <img src={twitter} alt="twitter" />
            </a>
            <a href="https://t.me/TMEcoinonbase">
              <img src={telegram} alt="telegram" />
            </a>
          </div>
        </div>
      </div>
    </CommunityStyle>
  )
}
