import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import logoImg from './assets/img/logo.png'
import navbarToggler from './assets/img/navbar-toggler.png'
import navBarBg from './assets/img/nav-bar-bg.png'
const MenuStyle = styled.div`
  @media screen and (max-width: 1000px) {
    .menu {
      padding: 0 20px !important;
      top: 20px !important;
      .left .logo {
        height: 50px !important;

      }
      .right {
        display: none !important;
      }
    }
    .m-menu {
      display: flex !important;
    }
    .navbar-toggler {
      display: block !important;
    }
  }
  .navbar-toggler {
    display: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    .navbar-toggler-icon {
      display: block;
      width: 100%;
      height: 100%;
      background-image: url(${navbarToggler});
      background-size: 100% 100%;
    }
  }
  .menu {
    display: flex;
    align-items: center;
    color: white;
    padding: 0 48px;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 50px;
    width: 100%;
    z-index: 1;
    .logo {
      height: 80px;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 24px;
      .item {
        cursor: pointer;
        color: #fff;
        font-size: 20px;
      }
    }
  }
  .navbar {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    z-index: 999;
    .navbar-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url(${navBarBg});
      background-size: 100% 100%;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    .navbar-wrapper {
      width: 40%;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 30px 50px;
      text-align: right;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      .navbar-nav {
        margin-top: 30px;
        margin-bottom: 0;
        .nav-item {
          a {
            -webkit-tap-highlight-color: transparent;
            color: #fff;
          }
        }
        .nav-item.active a {
          color: #ffe600;
        }
        .nav-item + .nav-item {
          margin-top: 20px;
        }
      }
    }
  }
`
export default function Menu() {
  const navMast = useRef(null)
  const navbarWrapper = useRef(null)
  const navbar = useRef(null)
  function openMenu() {
    document.body.style.overflow = 'hidden'
    navbar.current.style.display = 'block'
    setTimeout(() => {
      navMast.current.style.opacity = 1
      navbarWrapper.current.style.transform = 'none'
    }, 0)
  }
  function closeMenu() {
    document.body.style.overflow = 'auto'
    navbarWrapper.current.style.transform = 'translateX(100%)'
    navMast.current.style.opacity = 0
    setTimeout(() => {
      navbar.current.style.display = 'none'
    }, 300)
  }
  const [menuList] = useState([
    'Home',
    'About',
    'Project',
    'Community',
    'JoinUS',
  ])
  const [active, setActive] = useState(0)

  function menuChange(item, index) {
    setActive(index)
    closeMenu()
  }
  useEffect(() => {
    const hash = window.location.hash
    const formatHash = window.location.hash?.slice(1, hash.length)
    if (formatHash) {
      menuList.find((item, index) => {
        if (item === formatHash) {
          setActive(index)
        }
        return item === formatHash
      })
    }
  }, [])

  return (
    <MenuStyle>
      <div className="menu">
        <div className="left">
          <img src={logoImg} className="logo" alt="logo" />
        </div>
        <div className="right">
          <a className="item" href="#Home">
            Home
          </a>
          <a className="item" href="#About">
            About
          </a>
          <a className="item" href="#Project">
            Projects
          </a>
          <a className="item" href="#Community">
            Community
          </a>
          <a className="item" href="#JoinUS">
            Join US
          </a>
        </div>
        <div className="navbar-toggler" onClick={() => openMenu()}>
          <span className="navbar-toggler-icon"></span>
        </div>
      </div>
      <div className="navbar" ref={navbar}>
        <div
          className="navbar-mask"
          ref={navMast}
          onClick={() => closeMenu()}
        ></div>
        <div className="navbar-wrapper" ref={navbarWrapper}>
          <div className="navbar-toggler" onClick={() => closeMenu()}>
            <span className="navbar-toggler-icon"></span>
          </div>
          <ul className="navbar-nav">
            {menuList.map((item, index) => {
              return (
                <li
                  key={item}
                  className={active === index ? 'nav-item active' : 'nav-item'}
                  name="home"
                  onClick={() => menuChange(item, index)}
                >
                  <a className="item" href={'#' + item}>
                    {item}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </MenuStyle>
  )
}
