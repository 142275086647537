import React from 'react'
import styled from 'styled-components'
import aboutPcBg from './assets/img/home-pc-bg.png'
import aboutMBg from './assets/img/about-m-bg.png'
import trump from './assets/img/trump.png'
import ear from './assets/img/ear.png'

const AboutStyle = styled.div`
  @media screen and (max-width: 1000px) {
    .page-main {
      background: url(${aboutMBg}) !important;
      background-size: 100% 100% !important;
    }
    .m-avatar {
      display: block !important;
    }
    .about .detail img {
      display: none;
    }
    .item {
      height: unset !important;
      padding: 0 30px !important;
    }
    .sub-title {
      margin-top: 40px !important;
    }
    .detail {
      margin-top: 20px !important;
      .des {
        margin-left: 0 !important;
        font-size: 12px !important;
      }
    }
  }
  .page-main {
    width: 100%;
    min-height: 100vh;
    background: url(${aboutPcBg});
    background-size: 100% 100%;
    padding-top: 134px;
    padding-bottom: 50px;
    .item {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 24vw;
      .title {
        margin-top: 60px;
        font-size: 4vw;
        color: #fff;
        text-align: center;
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .sub-title {
          color: #ffe600;
          font-size: 4vw;
          margin-top: 100px;
        }
        .m-avatar {
          width: 100%;
          display: none;
        }
        .detail {
          display: flex;
          align-items: center;
          margin-top: 50px;
          img {
            width: 200px;
            height: 200px;
          }
          .des {
            width: 100%;
            color: #fff;
            font-size: 16px;
            margin-left: 20px;
            line-height: 1.5;
            .highlight-yellow {
              color: #ffe600;
              font-weight: bold;
            }
          }
        }
      }
    }
    .projects {
      .detail {
        margin-top: 85px;
        border: 2px solid #ffe600;
        border-radius: 5px;
        padding: 20px;
        background: linear-gradient(to bottom, #6bb3f8, #2e3dc8);
        .text {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          & + .text {
            margin-top: 20px;
          }
          span {
            color: #fff;
            font-weight: 600;
            font-size: 16px;
          }
          img {
            width: 50px;
            position: absolute;
            right: 0;
            top: 10px;
            transform: translate(20px, -50%);
          }
        }
      }
    }
  }
`
export default function About() {
  return (
    <AboutStyle>
      <div className="page-main" id="About">
        <div className="about item">
          <h5 className="title">About</h5>
          <div className="content">
            <img src={trump} alt="trump" className="m-avatar" />
            <h6 className="sub-title">TRUMP'S MISSING EAR:</h6>
            <div className="detail">
              <img src={trump} alt="trump" />
              <p className="des">
                <span className="highlight-yellow">TME </span> IS A SPIRITED WAY
                TO COMMEMORATE THE MOMENTOUS EVENT OF MR.TRUMP'S <br />
                COURAGEOUS STAND. OUR TOKEN SUPPLY IS UNIOUELY LIMITED TO ONLY 1
                EAR IN TOTAL.
                <br /> EVERYONE CAN EARN A PORTION OF THE TOKENS. BY COLLECTING
                PIECES OF A COMPLETE
                <br /> EAR, WE RALLY IN SUPPORT OF MR.TRUMP.
              </p>
            </div>
          </div>
        </div>
        <div className="projects item" id="Project">
          <h5 className="title">Token</h5>
          <div className="detail">
            <div className="text">
              <span>TOKEN SUPPLY: 1 </span>
              <img src={ear} alt="ear" />
            </div>

            <div className="text">
              <span>TOKEN NAME: TME</span>
            </div>
            <div className="text">
              <span>INITIAL NETWORK: BASE</span>
            </div>
            <div className="text">
              <span>CA: 0xc6182a09c8c80ab717495cf4c0d5a1042847a278</span>
            </div>
          </div>
        </div>
      </div>
    </AboutStyle>
  )
}
