import Menu from './Menu'
import Home from './Home'
import About from './About'
import Community from './Community'
function App() {
  return (
    <div>
      <Menu></Menu>
      <Home></Home>
      <About></About>
      <Community></Community>
    </div>
  )
}

export default App
